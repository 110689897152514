import { gql } from "@apollo/client";

export const GET_AGENT_ONLINE_DATA = gql`
  query GetAgentOnlineData {
    agentOnlineData {
      id
      agentCurrentStatus {
        canChat
        canVideoCall
        canVoiceCall
        status {
          id
          name
          systemStatus
        }
      }
    }
  }
`;

export const GET_AGENT_STATUS_CONFIGURATION = gql`
  query GetAgentStatusConfiguration {
    agentStatusConfiguration {
      id
      idleTimeout
    }
  }
`;
