import { gql } from "@apollo/client";

import { conversationListConversationFields } from "@/definitions/conversation/conversationListConversationDefinitions";
import * as conversationEventDefinitions from "@/definitions/conversation/event/conversationEventDefinitions";
import { lastVoiceConversationDefinition } from "@/definitions/conversation/lastVoiceConversation/lastVoiceConversationDefinition";
import * as sharedConversationDefinitions from "@/definitions/conversation/sharedConversationDefinitions";
import { videoSessionScreenshotDefinition } from "@/definitions/videoCallDefinitions";

/*
  This conversation definition must have all the properties that exist in the definition
  we use for the conversation list data at a minimum.
*/
const conversationDefinition = `{
    ${conversationListConversationFields}
    allowedActions
    eventsTotalCount
    lastVoiceConversation ${lastVoiceConversationDefinition}
    messageWindow {
      isOpen
    }
}`;

/* This definition must have every field present in conversationEventDefinition at a minimum */
const conversationEventSubscriptionDefinition = `{
    id
    created
    eventType
    actor {
        ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
        ... on BotUserObject  ${sharedConversationDefinitions.senderBotUserDefinition}
        ... on ContactObject ${sharedConversationDefinitions.contactDefinition}
        ... on VoiceProviderContactObject ${sharedConversationDefinitions.voiceProviderContactDefinition}
        ... on MessagingProviderContactObject ${sharedConversationDefinitions.messagingProviderContactDefinition} 
    }

    actionObject {
        ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
        ... on ContentTypeAllowedTagObject ${sharedConversationDefinitions.contentTypeAllowedTagDefinition}
        ... on ConversationMessageObject ${conversationEventDefinitions.conversationMessageDefinition}
        ... on ConversationNoteObject ${conversationEventDefinitions.conversationNoteDefinition}
        ... on ConversationSummaryObject ${sharedConversationDefinitions.conversationSummaryDefinition}
        ... on PaymentSessionObject ${conversationEventDefinitions.paymentSessionDefinition}
        ... on RecordingObject ${conversationEventDefinitions.recordingDefinition}
        ... on VideoSessionScreenshotObject ${videoSessionScreenshotDefinition}
        ... on VoiceProviderContactObject ${sharedConversationDefinitions.voiceProviderContactDefinition}
        ... on VoiceIVRInteractionObject ${conversationEventDefinitions.voiceIVRInteractionObjectDefinition}  
        ... on VoicemailObject ${conversationEventDefinitions.voicemailDefinition} 
        ... on VoiceConversationObject ${lastVoiceConversationDefinition}
        ... on VideoSessionArchiveObject ${conversationEventDefinitions.videoSessionArchiveDefinition}
        ... on LandingPageDocumentVisitLogObject ${conversationEventDefinitions.landingPageDocumentVisitLogDefinition}
    }
    
    context {
        ... on AgentAssignmentObject ${sharedConversationDefinitions.agentAssignmentContextDefinition}
    }

    target {
        ... on AgentObject ${sharedConversationDefinitions.agentDefinition}
        ... on GroupObject ${sharedConversationDefinitions.groupDefinition}
        ... on VoiceProviderContactObject ${sharedConversationDefinitions.voiceProviderContactDefinition}
        ... on VoiceProviderAccountObject ${sharedConversationDefinitions.voiceProviderAccountDefinition}  
        ... on RecordingObject ${conversationEventDefinitions.recordingDefinition}
        ... on VoiceConversationObject ${lastVoiceConversationDefinition}
        ... on ConversationNoteObject ${conversationEventDefinitions.conversationNoteDefinition}
    }

    conversation ${conversationDefinition}
}`;

export const CONVERSATION_EVENTS_LIST_SUBSCRIPTION = gql`
  subscription onNewConversationEvents {
    onNewConversationEvents {
      events ${conversationEventSubscriptionDefinition}
    }
  }
`;

export const GET_FALLBACK_EVENT = gql`
  query GetFallbackEvent($id: ID!) {
    fallbackEvent(id: $id) ${conversationEventSubscriptionDefinition}
  }
`;
