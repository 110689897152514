import parse, { domToReact } from "html-react-parser";
import { isEmpty, trim, uniq, uniqBy, zipObject } from "lodash";
import sanitizeHtml from "sanitize-html";

import {
  hsmTemplateButtonCategoryTypeEnum,
  hsmTemplateButtonTypeEnum,
  hsmTemplateMediaHeaderTypeEnum,
  hsmTemplateStatusEnum,
} from "@/enums/hsmEnum";

const HSM_VARIABLE_REGEX = /{{((?:\w+)|(?:\w[\w ]+\w))}}/g;
const HSM_VARIABLE_NAME_LENGTH_LIMIT = 30;

export const MAX_WHATSAPP_HSM_TEMPLATE_FOLDERS_LIMIT = 30;

export const UNCATEGORISED_HSM_SECTION_ID = null;

export const mediaHeaderTypes = Object.values(hsmTemplateMediaHeaderTypeEnum);

export const hsmTemplateMediaHeaderLabelMapping = {
  [hsmTemplateMediaHeaderTypeEnum.IM]: "Image",
  [hsmTemplateMediaHeaderTypeEnum.VI]: "Video",
  [hsmTemplateMediaHeaderTypeEnum.DO]: "Document",
};

export const buttonCategoryOptions = [
  {
    value: hsmTemplateButtonCategoryTypeEnum.none,
    label: "None",
    buttonLimit: 0,
  },
  {
    value: hsmTemplateButtonCategoryTypeEnum.quickReply,
    label: "Quick Reply",
    buttonLimit: 3,
  },
  {
    value: hsmTemplateButtonCategoryTypeEnum.callToAction,
    label: "Call-to-Action",
    buttonLimit: 2,
  },
];

export const defaultQuickReplyHsmButtonObject = { buttonText: "" };

export const defaultCallToActionHsmButtonObject = {
  actionType: null,
  buttonText: "",
  value: "",
  sampleValue: "",
};

export const defaultHsmGroupSelectorOption = {
  value: "allGroups",
  label: "All Groups",
};

export const getMutationGroupsInput = ({ groups }) => {
  const isAllGroupsSelected = groups.some(
    ({ value }) => value === defaultHsmGroupSelectorOption.value,
  );

  if (isAllGroupsSelected) return { groupIds: [] };

  return { groupIds: groups.map(({ value }) => value) };
};

export const checkIsHsmWithMediaHeader = (headerType) => {
  return mediaHeaderTypes.includes(headerType);
};

export const handleTemplateNameInputChange = ({
  controlName,
  control,
  event,
}) => {
  const value = event.target.value;
  const isValidKeyword = /^[a-z0-9_]*$/.test(value);

  if (isValidKeyword) {
    control.setValue(controlName, value, { shouldDirty: true });
  }
};

export const getTemplateStatusLabelColor = ({ status, theme }) => {
  switch (status) {
    case hsmTemplateStatusEnum.APPROVED: {
      return theme.palette.success[1650];
    }

    case hsmTemplateStatusEnum.REJECTED:
    case hsmTemplateStatusEnum.DELETED:
    case hsmTemplateStatusEnum.DISABLED:
    case hsmTemplateStatusEnum.LOCKED: {
      return theme.palette.alert[420];
    }

    case hsmTemplateStatusEnum.PENDING: {
      return theme.palette.grey[1585];
    }

    case hsmTemplateStatusEnum.PENDING_DELETION:
    case hsmTemplateStatusEnum.IN_APPEAL: {
      return theme.palette.textSecondary.main;
    }

    default: {
      return theme.palette.textPrimary.main;
    }
  }
};

export const parseHsmHtmlString = ({ htmlString, HsmParameterRenderer }) => {
  const sanitizedHtml = sanitizeHtml(htmlString, {
    allowedTags: ["b", "strong", "i", "em", "p", "span", "br", "data"],
    disallowedTagsMode: "escape",
    parser: { lowerCaseTags: false },
    allowedAttributes: {
      data: ["data-object"],
    },
  });

  const options = {
    replace: ({ children, name, attribs }) => {
      if (name === "data" && attribs?.["data-object"] === "hsm_parameter") {
        const hsmParamName = children[0].data;

        if (!HsmParameterRenderer) return domToReact(children, options);

        return (
          <HsmParameterRenderer hsmParamName={hsmParamName}>
            {domToReact(children, options)}
          </HsmParameterRenderer>
        );
      }
    },
  };

  return parse(sanitizedHtml, options);
};

export const getParameterArrayFromTranslatedTemplate = ({
  whatsappHsmTranslatedTemplate,
  returnParamObjects,
}) => {
  const { headerTextParams, bodyTextParams, buttonUrlParams } =
    whatsappHsmTranslatedTemplate;
  const params = [...headerTextParams, ...bodyTextParams, ...buttonUrlParams];

  if (returnParamObjects) return uniqBy(params, "hint");
  return uniq(params.map((param) => param.hint));
};

export const getHsmParamNameValueMap = ({
  whatsappHsmTranslatedTemplate,
  inputParamsArray,
}) => {
  /* Get array of unique parameter names that are present throughout the template */
  const paramNameArray = getParameterArrayFromTranslatedTemplate({
    whatsappHsmTranslatedTemplate,
  });
  const paramNameToValueMap = zipObject(paramNameArray, inputParamsArray);

  return paramNameToValueMap;
};

export const getHsmVariablesFromString = (valueString = "") => {
  const variables = valueString.match(HSM_VARIABLE_REGEX) || [];
  const trimmedVariables = variables.map((variable) => trim(variable, "{}"));

  return { variables, trimmedVariables };
};

export const validateStringWithHsmVariables = (plaintext = "") => {
  /* Match all text within single curly brace */
  const singleCurlyBraceTextMatches = plaintext.match(/{+([^}]+)}+/g);

  /* Check if there is any text that does not start/end with double curly braces */
  const hasInvalidVariable = singleCurlyBraceTextMatches?.some((text) => {
    const { variables } = getHsmVariablesFromString(text);
    return isEmpty(variables);
  });

  if (hasInvalidVariable) {
    return "Invalid variables found. Please make sure variable is defined in {{ }}";
  }

  const { trimmedVariables } = getHsmVariablesFromString(plaintext);
  const hasLongName = trimmedVariables.some(
    (variable) => variable.length > HSM_VARIABLE_NAME_LENGTH_LIMIT,
  );

  if (hasLongName) {
    return `Variable names must be no more than ${HSM_VARIABLE_NAME_LENGTH_LIMIT} characters`;
  }

  return true;
};

export const getHsmButtonCategoryFromButtonType = (buttonType) => {
  switch (buttonType) {
    case hsmTemplateButtonTypeEnum.QR: {
      return buttonCategoryOptions.find(
        (item) => item.value === hsmTemplateButtonCategoryTypeEnum.quickReply,
      );
    }

    case hsmTemplateButtonTypeEnum.PN:
    case hsmTemplateButtonTypeEnum.PY:
    case hsmTemplateButtonTypeEnum.UR: {
      return buttonCategoryOptions.find(
        (item) => item.value === hsmTemplateButtonCategoryTypeEnum.callToAction,
      );
    }

    default: {
      return null;
    }
  }
};
