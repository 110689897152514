import { notificationEventDefinition } from "./event/notificationEventDefinitions";

export const partialNotificationDefinition = `
  id
  created
  lastReadAt
  isMarkedUnread
`;

export const notificationDefinition = `{
  ${partialNotificationDefinition}
  event ${notificationEventDefinition}
}
`;
