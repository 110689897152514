export const opentokSession = (state) => state.privateVideoCall.opentokSession;

export const changedStream = (state) => state.privateVideoCall.changedStream;

export const streams = (state) => state.privateVideoCall.streams;

export const connections = (state) => state.privateVideoCall.connections;

export const previousPublisher = (state) =>
  state.privateVideoCall.previousPublisher;

export const callTimer = (state) => state.privateVideoCall.callTimer;

export const waitingTimer = (state) => state.privateVideoCall.waitingTimer;

export const hasVideoSession = (state) =>
  state.privateVideoCall.hasVideoSession;

export const conversationIdStartingVideoCall = (state) =>
  state.privateVideoCall.conversationIdStartingVideoCall;

export const ongoingVideoCallConversationId = (state) =>
  state.privateVideoCall.ongoingVideoCallConversationId;

export const isFloatingVideoCall = (state) =>
  state.privateVideoCall.isFloatingVideoCall;

const isVideoSessionDataLoading = (state) =>
  state.privateVideoCall.isVideoSessionDataLoading;

export const isScreenshotLoading = (state) =>
  state.privateVideoCall.isScreenshotLoading;

export const isVideoCallInUse = (state) => {
  const hasOngoingVideoSession = hasVideoSession(state);
  const isStartingVideoCall = !!conversationIdStartingVideoCall(state);
  const isLoadingVideoSessionData = isVideoSessionDataLoading(state);

  return (
    hasOngoingVideoSession || isStartingVideoCall || isLoadingVideoSessionData
  );
};
