import { makeStyles } from "@material-ui/core";
import { useEffect, useRef } from "react";

import * as PrivateVideoCallProvider from "@/contextProviders/CallProvider/PrivateVideoCallProvider";
import hexTransparencyEnum from "@/enums/hexTransparencyEnum";
import {
  videoCallControlEnum,
  videoCallParticipantTypeEnum,
} from "@/enums/videoCall";
import { useValueRef } from "@/utils/hookUtils";
import * as videoCallUtils from "@/utils/videoCallUtils";
import ControlBar from "../ControlBar";
import RecordingIndicator from "../ControlBar/ActionButtons/RecordingIndicator";
import LayoutContainer from "../LayoutContainer";
import videoCallHooks from "../videoCallHooks";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  floatingVideoCallContainer: {
    width: "304px",
    height: "270px",
    borderRadius: "4px",
    boxShadow: `0px 2px 4px ${theme.palette.common.black}${hexTransparencyEnum[22]}`,
    backgroundColor: theme.palette.grey[3200],
    color: theme.palette.common.white,
  },

  videoCallContainer: {
    position: "relative",
    margin: "auto",
    width: "292px",
    height: "176px",
  },
}));

const controls = {
  [videoCallControlEnum.AUDIO]: true,
  [videoCallControlEnum.VIDEO]: true,
  [videoCallControlEnum.SCREENSHOT]: true,
};

const FloatingVideoCallWidget = ({ ongoingVideoCall }) => {
  const classes = useStyles();
  const containerRef = useRef();

  const {
    opentokSession,
    connections,
    streams,
    changedStream,
    previousPublisher,
    onSetPreviousPublisher,
  } = PrivateVideoCallProvider.usePrivateVideoCallContext();

  const {
    isScreenshotLoading,
    onScreenshot,
    onToggleCamera,
    onToggleMicrophone,
  } = PrivateVideoCallProvider.usePrivateVideoCallMeetingRoomContext();

  const { conversation, videoProviderSession } = ongoingVideoCall;
  const { shouldAutoArchive } = videoProviderSession.videoProviderAccount;

  const cameraPublisher = videoCallHooks.usePublisher({
    opentokSession,
    containerId: `floatingCameraPublisherContainer-${conversation.id}`,
    changedStream,
  });
  const subscriber = videoCallHooks.useSubscriber({
    opentokSession,
    containerId: `floatingMainVideoCallContainer-${conversation.id}`,
  });

  const agents = videoCallUtils.getAgentList({
    publisherStream: cameraPublisher.stream,
    subscriberStreams: streams,
  });

  const contacts = videoCallUtils.getContactList({ streams });

  const hasContactJoined = connections.some(({ data }) => {
    const parsedData = JSON.parse(data);
    return parsedData.participant_type === videoCallParticipantTypeEnum.CONTACT;
  });

  const valueRefs = useValueRef({
    cameraPublisher,
    previousPublisher,
    onSetPreviousPublisher,
    subscriber,
  });

  useEffect(() => {
    const { cameraPublisher, previousPublisher } = valueRefs.current;

    if (opentokSession) {
      if (previousPublisher) previousPublisher.unpublish();

      cameraPublisher.publish();
    }
  }, [valueRefs, opentokSession]);

  useEffect(() => {
    const { cameraPublisher, onSetPreviousPublisher } = valueRefs.current;
    if (cameraPublisher.publisher) {
      onSetPreviousPublisher(cameraPublisher);
    }
  }, [valueRefs, cameraPublisher.publisher]);

  useEffect(() => {
    if (opentokSession && streams) {
      valueRefs.current.subscriber.subscribeToStreams(streams);
    }
  }, [valueRefs, opentokSession, streams]);

  if (!opentokSession) return null;

  return (
    <div ref={containerRef} className={classes.floatingVideoCallContainer}>
      <Header
        contactDisplayName={ongoingVideoCall?.contact?.displayName}
        conversationId={conversation.id}
        hasContactJoined={hasContactJoined}
      />
      <div className={classes.videoCallContainer}>
        {shouldAutoArchive && <RecordingIndicator />}

        <LayoutContainer
          isFloatingCameraPublisher
          elementId={`floatingCameraPublisherContainer-${conversation.id}`}
          cameraPublisher={cameraPublisher}
          isFullScreen={!hasContactJoined}
        />
        <LayoutContainer
          isFullScreen
          elementId={`floatingMainVideoCallContainer-${conversation.id}`}
          subscribers={subscriber.subscribers}
        />
      </div>

      <ControlBar
        isPrivateCall
        controls={controls}
        cameraPublisher={cameraPublisher}
        cameraSubscribers={subscriber.subscribers[0]}
        contacts={contacts}
        agents={agents}
        isScreenshotLoading={isScreenshotLoading}
        onScreenshot={() => onScreenshot(containerRef)}
        onToggleCamera={() => onToggleCamera(cameraPublisher)}
        onToggleMicrophone={() => onToggleMicrophone(cameraPublisher)}
      />
    </div>
  );
};
export default FloatingVideoCallWidget;
