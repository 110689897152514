export const parentConversationId = (state) =>
  state.conversation.parentConversationId;

export const isMediaPreviewShown = (state) =>
  state.conversation.isMediaPreviewShown;

export const currentMediaPreviewId = (state) =>
  state.conversation.currentMediaPreviewId;

export const currentMediaCursorOffset = (state) =>
  state.conversation.currentMediaCursorOffset;

export const firstItemIndex = (state) => state.conversation.firstItemIndex;

export const firstConversationEventId = (state) =>
  state.conversation.firstConversationEventId;

export const conversationInitiationState = (state) =>
  state.conversation.conversationInitiationState;

export const focusedConversationNextPositionData = (state) =>
  state.conversation.focusedConversationNextPositionData;

export const isVoiceCallWithOtherAgent = (state) =>
  state.conversation.isVoiceCallWithOtherAgent;
