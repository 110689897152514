import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import * as VoiceCallProvider from "@/contextProviders/CallProvider/VoiceCallProvider";
import { voiceCallCandidateTypeEnum } from "@/enums/voiceCall";
import InviteParticipantActionButtons from "./InviteParticipantActionButtons";
import SearchVoiceCandidates from "./SearchVoiceCandidates";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "360px",
    height: "395px",
    alignItems: "center",
  },

  dialogTitle: {
    color: theme.palette.textPrimary.main,
    padding: "15px 0px 10px",
  },

  dialogContent: {
    width: "90%",
    padding: "unset",
    overflow: "hidden",
  },

  dialogActions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "16px",
    padding: "17px 0px 19px",
  },
}));

const VoiceCallInvitationModal = () => {
  const classes = useStyles();

  const { control, errors, handleSubmit } = useForm({
    defaultValues: { voiceCallCandidates: [] },
  });

  const {
    activeConversationVoiceCallId,
    isVoiceCallInvitationModalShown,
    isInviteToVoiceConversationLoading,
    onCloseVoiceCallInvitationModal,
    onInviteParticipantToVoiceCall,
  } = VoiceCallProvider.useVoiceCallInvitationModalContext();

  const handleAddParticipants = ({ voiceCallCandidates }) => {
    const { targetAgentIds, targetVoiceCallContactCandidates } =
      voiceCallCandidates.reduce(
        (accumulator, candidateOption) => {
          switch (candidateOption.candidateType) {
            case voiceCallCandidateTypeEnum.agent: {
              accumulator.targetAgentIds.push(candidateOption.value);
              break;
            }
            case voiceCallCandidateTypeEnum.contact: {
              accumulator.targetVoiceCallContactCandidates.push({
                phoneNumber: candidateOption.phoneNumber,
                contact: candidateOption.contact.id,
              });
              break;
            }
            case voiceCallCandidateTypeEnum.newContact: {
              accumulator.targetVoiceCallContactCandidates.push({
                phoneNumber: candidateOption.value,
              });
              break;
            }
          }
          return accumulator;
        },
        { targetAgentIds: [], targetVoiceCallContactCandidates: [] },
      );

    onInviteParticipantToVoiceCall({
      voiceConversationId: activeConversationVoiceCallId,
      targetAgentIds,
      targetVoiceCallContactCandidates,
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isVoiceCallInvitationModalShown}
      onClose={onCloseVoiceCallInvitationModal}
    >
      <DialogTitle className={classes.dialogTitle}>
        Add participants to call
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Controller
          control={control}
          name="voiceCallCandidates"
          render={({ value, onChange }) => {
            return (
              <SearchVoiceCandidates
                value={value}
                error={Boolean(errors.voiceCallCandidates)}
                onChange={onChange}
              />
            );
          }}
        />
      </DialogContent>

      <Divider style={{ width: "100%" }} />

      <DialogActions classes={{ root: classes.dialogActions }}>
        <InviteParticipantActionButtons
          control={control}
          loading={isInviteToVoiceConversationLoading}
          onAddParticipants={handleSubmit(handleAddParticipants)}
          onModalClose={onCloseVoiceCallInvitationModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default VoiceCallInvitationModal;
