import { gql } from "@apollo/client";

/* These definitions should only be used by the inbox views pane in inbox page */

export const agentCurrentStatusDefinition = ` {
  canChat
  canVoiceCall
  canVideoCall
  status {
    id
    systemStatus
  }
}`;

export const agentDefinition = `{
  id
  isPinned
  user {
    firstName
    lastName
    email    
  }
  agentCurrentStatus ${agentCurrentStatusDefinition} 
}`;

export const groupDefinition = `{
  id
  isPinned
  name
}`;

export const contentTypeAllowedTagDefinition = `{
  id
  isPinned
  tag {
    id
    name
  }
}`;

export const AGENT_INBOX_VIEW_PANE_FRAGMENT = gql`
  fragment AgentInboxViewPaneFragment on AgentObject ${agentDefinition}
`;
