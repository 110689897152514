import dynamic from "next/dynamic";
import BaseDraggable from "react-draggable";

import { useIsMobileDeviceCheck } from "@/utils/hookUtils";
import { emptyObj } from "@/utils/noopUtils";

const MobileDraggable = dynamic(() => import("./MobileDraggable.js"), {
  ssr: false,
});

const Draggable = ({ children, BaseDraggableProps = emptyObj }) => {
  const isMobileDevice = useIsMobileDeviceCheck();

  if (isMobileDevice) {
    return (
      <MobileDraggable BaseDraggableProps={BaseDraggableProps}>
        {children}
      </MobileDraggable>
    );
  }

  return <BaseDraggable {...BaseDraggableProps}>{children}</BaseDraggable>;
};

export default Draggable;
