const mediaUploads =
  ({ editorName }) =>
  (state) =>
    state.media[editorName] || {};

export const mediaUpload =
  ({ key, editorName }) =>
  (state) =>
    state.media[editorName]?.[key] || {};

export const mediaUuid =
  ({ key, editorName }) =>
  (state) =>
    state.media[editorName]?.[key]?.uuid || "";

export const mediaStatus =
  ({ key, editorName }) =>
  (state) =>
    state.media[editorName]?.[key]?.status || "";

export const mediaUploadStates = ({
  state,
  richTextEditorName,
  editorHelpers,
}) => {
  const mediaUploadData = mediaUploads({ editorName: richTextEditorName })(
    state,
  );

  const uploadStates = editorHelpers.map((editorHelper) => {
    return editorHelper.getMediaUuids({ mediaUploads: mediaUploadData });
  });

  return uploadStates;
};
