import { useApolloClient } from "@apollo/client";
import { useTheme } from "@material-ui/core";
import { get, isEmpty } from "lodash";
import { useMemo } from "react";

import { dataObjectTypenameEnum } from "@/enums/typename";
import { voiceCallCandidateTypeEnum } from "@/enums/voiceCall";
import * as commonUtils from "@/utils/commonUtils";
// import { validateNonEmptyString } from "@/utils/formValidationUtils";
import Selector from "@/components/Selector";
import {
  // GET_GROUPS,
  GET_VOICE_CALL_CANDIDATES,
} from "@/queries/voiceCallQueries";
import { useDebounce } from "@/utils/hookUtils";
import { validateSearchContactPhoneNumber } from "@/utils/inboxPageUtils";
import { searchForAsyncSelectOptions } from "@/utils/selectUtils";
import NoOptionsMessage from "./NoOptionsMessage";
import { MultiValueLabel, MultiValueRemove } from "./VoiceCandidateMultiValue";
import Option from "./VoiceCandidateOption";
import {
  ClearIndicator,
  DropdownIndicator,
} from "./VoiceCandidateSelectIndicator";

const getSelectorOptions = (optionData) => {
  switch (optionData.__typename) {
    case dataObjectTypenameEnum.agentObject:
      return {
        candidateType: voiceCallCandidateTypeEnum.agent,
        label: commonUtils.getAgentFullName(optionData),
        value: optionData.id,
      };
    case dataObjectTypenameEnum.voiceCallContactCandidateObject:
      return {
        candidateType: voiceCallCandidateTypeEnum.contact,
        label: optionData.contact.displayName,
        value: optionData.contact.displayName,
      };
    default:
      return {};
  }
};

const Placeholder = (
  <>
    Add <i>teammate, contact or via phone number</i>
  </>
);

/* TODO: Post-MVP - Allow group-invite once related backend ticket is done (MSG-1703) */
const getSearchVoiceCandidateQuery = (searchTerm) => {
  // const hasSearchTerm = validateNonEmptyString(searchTerm);

  // const groupsQuery = {
  //   query: GET_GROUPS,
  //   queryObjectListPath: "groups",
  //   selectorOptionSettings: {
  //     valuePath: "id",
  //     labelPath: "name",
  //   },
  //   variables: { limit: DEFAULT_PAGINATION_LIMIT },
  // };

  const voiceCallCandidatesQuery = {
    query: GET_VOICE_CALL_CANDIDATES,
    queryObjectListPath: "voiceCallCandidates",
    variables: { search: searchTerm },
  };

  // return hasSearchTerm ? voiceCallCandidatesQuery : groupsQuery;
  return voiceCallCandidatesQuery;
};

const getNewContactOptions = ({ searchTerm, options }) => {
  const { isValid } = validateSearchContactPhoneNumber(searchTerm);
  const isNewContact = isValid && isEmpty(options);

  if (!isNewContact) return {};

  const newContactOptions = [
    {
      label: "NEW PHONE NUMBER",
      options: [
        {
          label: searchTerm,
          value: searchTerm,
          candidateType: voiceCallCandidateTypeEnum.newContact,
        },
      ],
    },
  ];

  return { isNewContact, newContactOptions };
};

const prepareSelectorOptions = ({
  data,
  searchTerm,
  searchVoiceCandidateQuery,
  setOptions,
}) => {
  const optionsData = get(data, searchVoiceCandidateQuery.queryObjectListPath);

  const options = optionsData.map((optionData) => {
    return {
      ...optionData,
      ...getSelectorOptions(optionData),
    };
  });

  const { isNewContact, newContactOptions } = getNewContactOptions({
    searchTerm,
    options,
  });

  const selectorOptions = (() => {
    // if (!searchTerm) return [{ label: "TEAMMATE FROM GROUP", options }];
    if (isNewContact) return newContactOptions;

    /* Otherwise, return agents and/or existing contacts */
    return options;
  })();

  setOptions(selectorOptions);
};

const SearchVoiceCandidates = ({ value, error, onChange }) => {
  const client = useApolloClient();
  const theme = useTheme();

  const handleFilterOptions = useDebounce({
    func: (searchTerm, setOptions) => {
      const searchVoiceCandidateQuery =
        getSearchVoiceCandidateQuery(searchTerm);

      const { query, variables } = searchVoiceCandidateQuery;

      searchForAsyncSelectOptions({
        client,
        query,
        variables,
        onSuccessCallback: (data) =>
          prepareSelectorOptions({
            data,
            searchTerm,
            searchVoiceCandidateQuery,
            setOptions,
          }),
        onErrorCallback: () => setOptions([]),
      });
    },
    delay: 500,
  });

  const customStyles = useMemo(
    () => ({
      control: (provided, state) => {
        const { isFocused } = state;
        const borderColor = (() => {
          if (isFocused) return theme.palette.primary.main;
          if (error) return theme.palette.alert.main;
          return theme.palette.primaryBorder.main;
        })();

        return {
          display: "flex",
          minHeight: "36px",
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
        };
      },
      group: (provided) => ({
        ...provided,
        padding: "0",
      }),
      groupHeading: (provided) => ({
        ...provided,
        color: theme.palette.primary.main,
        fontSize: "0.85rem",
        padding: "0",
        margin: "4px 0px 7px 7px",
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: "4px",
        boxShadow: "unset",
      }),
      menuList: (provided) => {
        return {
          ...provided,
          padding: "unset",
          margin: "4px 0px",
          maxHeight: "223px",
        };
      },
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: theme.palette.grey[1780],
        borderRadius: "3px",
      }),
      option: (provided, state) => {
        const { isFocused } = state;

        return {
          ...provided,
          height: "30px",
          cursor: "pointer",
          backgroundColor: isFocused ? theme.palette.grey[370] : "unset",
          ":hover": { backgroundColor: theme.palette.primaryBorder.main },
        };
      },
    }),
    [theme, error],
  );

  return (
    <Selector
      isAsync
      isMulti
      isClearable
      autoFocus
      openMenuOnFocus
      value={value}
      placeholder={Placeholder}
      customStyles={customStyles}
      error={error}
      components={{
        ClearIndicator,
        DropdownIndicator,
        MultiValueLabel,
        MultiValueRemove,
        Option,
        NoOptionsMessage,
      }}
      onChange={onChange}
      loadOptions={handleFilterOptions}
    />
  );
};

export default SearchVoiceCandidates;
