import { useApolloClient, useSubscription } from "@apollo/client";
import { makeStyles, useTheme } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";

import { setShouldRefetchInstances } from "@/actions/crmPageActions";
import MentionIcon from "@/assets/images/customizableSVG/icon-mention";
import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import { conversationEventTypeEnum } from "@/enums/conversation";
import { dataObjectTypenameEnum } from "@/enums/typename";
import { NOTIFICATION_SUBSCRIPTION } from "@/queries/notificationQueries";
import {
  crmContactTagBulkOperationResponseEnum,
  crmInstanceListBulkOperationResponseEnum,
} from "@/src/components/CRM/CRMPage/enums";
import * as graphqlUtils from "@/utils/graphqlUtils";
import { getConversationEventUnionObjectLabel } from "@/utils/inboxPageUtils";

const useStyles = makeStyles(() => ({
  messageContainer: {
    display: "flex",
    alignItems: "center",
  },

  iconContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    marginRight: "10px",
  },
}));

const NotificationSubscription = ({ onPlayNotificationSound }) => {
  const theme = useTheme();
  const classes = useStyles();

  const client = useApolloClient();
  const dispatch = useDispatch();

  const { onSetAppSnackbarProps } = useAppSnackbar();

  const setMentionedSnackbar = (message) => {
    onSetAppSnackbarProps({
      message: (
        <div className={classes.messageContainer}>
          <div className={classes.iconContainer}>
            <MentionIcon
              fill={theme.palette.success.light}
              height="14"
              width="14"
            />
          </div>
          {message}
        </div>
      ),
    });
  };

  const handleAgentMentioned = ({ notificationEvent }) => {
    const mentionBy = getConversationEventUnionObjectLabel(
      notificationEvent.actor,
    );

    setMentionedSnackbar(
      `You have been mentioned${mentionBy ? ` by ${mentionBy}` : ""}`,
    );
    onPlayNotificationSound();
  };

  const handleSpecificNotificationEventType = ({
    notificationEventType,
    ...otherProps
  }) => {
    switch (notificationEventType) {
      case conversationEventTypeEnum.agentMentioned: {
        handleAgentMentioned(otherProps);
        break;
      }

      case crmContactTagBulkOperationResponseEnum.CRM_CONTACT_TAG_BULK_ADD_SUCCESS:
      case crmContactTagBulkOperationResponseEnum.CRM_CONTACT_TAG_BULK_REMOVE_SUCCESS:
      case crmInstanceListBulkOperationResponseEnum.CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_SUCCESS:
      case crmInstanceListBulkOperationResponseEnum.CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_SUCCESS: {
        dispatch(setShouldRefetchInstances(true));
        break;
      }

      default: {
        break;
      }
    }
  };

  const handleNotificationQueryUpdate = ({ existing, notification }) => {
    if (isEmpty(existing))
      return {
        totalCount: 1,
        results: [notification],
        __typename: dataObjectTypenameEnum.notificationObjectListObject,
      };

    return {
      totalCount: existing.totalCount + 1,
      results: [notification, ...existing.results],
      __typename: dataObjectTypenameEnum.notificationObjectListObject,
    };
  };

  const handleIncomingNotificationEvent = (
    notification,
    newNotificationsCount,
  ) => {
    const { event: notificationEvent = {} } = notification;
    const { eventType: notificationEventType } = notificationEvent;

    const notificationsQueryKey = graphqlUtils.getRootQueryKey({
      queryName: "notifications",
    });

    const unreadNotificationsQueryKey = graphqlUtils.getRootQueryKey({
      queryName: "notifications",
      keyArgArray: [{ keyArg: "isUnread", value: true }],
    });

    client.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        newNotificationsCount: () => newNotificationsCount,

        /* Update normal query. */
        [notificationsQueryKey]: (existing) =>
          handleNotificationQueryUpdate({ existing, notification }),

        /* Update unread query in case they are in view. */
        [unreadNotificationsQueryKey]: (existing) =>
          handleNotificationQueryUpdate({ existing, notification }),
      },
    });

    handleSpecificNotificationEventType({
      notificationEvent,
      notificationEventType,
    });
  };

  useSubscription(NOTIFICATION_SUBSCRIPTION, {
    errorPolicy: "all",
    onData: ({ data }) => {
      const {
        data: {
          onNewNotification: {
            notification = {},
            newNotificationsCount = 0,
          } = {},
        } = {},
      } = data;

      handleIncomingNotificationEvent(notification, newNotificationsCount);
    },
  });

  return null;
};

export default NotificationSubscription;
