import { useMutation, useQuery } from "@apollo/client";
import { isFinite, pick } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";

import { useAuthContext } from "@/contextProviders/AuthProvider";
import { useLeaderElection } from "@/contextProviders/LeaderElectionProvider";
import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import { severityEnum } from "@/enums/styleVariantEnum";
import * as voiceCallSelectors from "@/selectors/voiceCallSelectors";
import { useValueRef } from "@/utils/hookUtils";
import { UPDATE_AGENT_CURRENT_STATUS } from "./mutations";
import {
  GET_AGENT_ONLINE_DATA,
  GET_AGENT_STATUS_CONFIGURATION,
} from "./queries";

const AgentStatusContext = createContext({});

const AgentStatusProvider = ({ children }) => {
  const { onSetAppSnackbarProps } = useAppSnackbar();
  const { isLeaderTab } = useLeaderElection();
  const { isUserLoggedIn } = useAuthContext();
  const { isVoiceFeatureUsedInAnotherTab } = useLeaderElection();

  const isVoiceCallInUse = useSelector(voiceCallSelectors.isVoiceCallInUse);

  const isAgentUsingVoiceFeature =
    isVoiceCallInUse || isVoiceFeatureUsedInAnotherTab;

  const { data: agentOnlineData } = useQuery(GET_AGENT_ONLINE_DATA, {
    skip: !isUserLoggedIn,
  });

  const { data: agentStatusConfigurationData } = useQuery(
    GET_AGENT_STATUS_CONFIGURATION,
    { skip: !isUserLoggedIn },
  );

  const agentCurrentStatus =
    agentOnlineData?.agentOnlineData.agentCurrentStatus;

  const agentStatusConfiguration =
    agentStatusConfigurationData?.agentStatusConfiguration;

  const [
    updateAgentCurrentStatus,
    { loading: isUpdateAgentCurrentStatusLoading },
  ] = useMutation(UPDATE_AGENT_CURRENT_STATUS, {
    onError: ({ message }) => {
      onSetAppSnackbarProps({
        message,
        AlertProps: { severity: severityEnum.error },
      });
    },
  });

  const timeout = useMemo(() => {
    const isIdleTimeoutValid =
      isFinite(agentStatusConfiguration?.idleTimeout) &&
      agentStatusConfiguration?.idleTimeout > 0;

    /* "timeout" given to useIdleTimer must be a number greater than 0 */
    if (!isIdleTimeoutValid) return undefined;
    return agentStatusConfiguration.idleTimeout * 60 * 1000;
  }, [agentStatusConfiguration?.idleTimeout]);

  const handleUpdateAgentCurrentStatus = (input) => {
    if (!isUserLoggedIn) return;
    if (isUpdateAgentCurrentStatusLoading) return;

    updateAgentCurrentStatus({ variables: { input } });
  };

  const valueRefs = useValueRef({
    isLeaderTab,
    agentCurrentStatus,
    handleUpdateAgentCurrentStatus,
  });

  const handleOnIdle = useCallback(() => {
    const { isLeaderTab, handleUpdateAgentCurrentStatus } = valueRefs.current;
    if (!isLeaderTab) return;

    handleUpdateAgentCurrentStatus({ toIdleStatus: true });
  }, [valueRefs]);

  const handleOnActive = useCallback(() => {
    const { isLeaderTab, handleUpdateAgentCurrentStatus } = valueRefs.current;
    if (!isLeaderTab) return;

    handleUpdateAgentCurrentStatus({ toPreviousStatus: true });
  }, [valueRefs]);

  const { start, pause } = useIdleTimer({
    startManually: true,
    timeout,
    throttle: 200,
    crossTab: true,
    syncTimers: 200,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
  });

  const effectRefs = useValueRef({ start, pause });

  /* This effect is responsible for starting and stopping the idle timer */
  useEffect(() => {
    const { start, pause } = effectRefs.current;

    const shouldStartTimer =
      !!timeout && isUserLoggedIn && !isAgentUsingVoiceFeature;

    shouldStartTimer ? start() : pause();
  }, [effectRefs, isUserLoggedIn, timeout, isAgentUsingVoiceFeature]);

  const agentCurrentStatusData = useMemo(() => {
    if (!agentCurrentStatus) return null;

    return pick(agentCurrentStatus, [
      "canChat",
      "canVideoCall",
      "canVoiceCall",
      "status",
    ]);
  }, [agentCurrentStatus]);

  const agentStatusContextValueRefs = useValueRef({
    handleUpdateAgentCurrentStatus,
  });
  const agentStatusContextValue = useMemo(() => {
    return {
      agentCurrentStatusData,
      onUpdateAgentCurrentStatus: (...args) =>
        agentStatusContextValueRefs.current.handleUpdateAgentCurrentStatus(
          ...args,
        ),
    };
  }, [agentStatusContextValueRefs, agentCurrentStatusData]);

  return (
    <AgentStatusContext.Provider value={agentStatusContextValue}>
      {children}
    </AgentStatusContext.Provider>
  );
};

export const useAgentStatus = () => useContext(AgentStatusContext);

export default AgentStatusProvider;
