import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import { severityEnum } from "@/enums/styleVariantEnum";
import { useIsMobileDeviceCheck, useValueRef } from "@/utils/hookUtils";
import { notificationPermissionEnum } from "./enums";
import { CREATE_WEB_PUSH_DEVICE } from "./mutations";
import * as pushNotificationUtils from "./utils";

const PushNotificationManager = () => {
  const { onSetAppSnackbarProps } = useAppSnackbar();
  const isMobileDevice = useIsMobileDeviceCheck();

  const [createWebPushDevice] = useMutation(CREATE_WEB_PUSH_DEVICE, {
    onError: ({ message }) => {
      onSetAppSnackbarProps({
        message,
        AlertProps: { severity: severityEnum.error },
      });
    },
  });

  const handleError = (error) => {
    const errorMessage = `Error in PushNotificationManager: ${error}`;
    console.error(errorMessage);
    Sentry.captureException(new Error(errorMessage));
  };

  /** Setup push notification subscription for the agent on the current browser */
  const setupPushNotification = async () => {
    try {
      await navigator.serviceWorker.register("/sw.js");
      const serviceWorkerRegistration = await navigator.serviceWorker.ready;

      const existingSubscription =
        await serviceWorkerRegistration.pushManager.getSubscription();

      /* getSubscription() always resolves to either PushSubscription object or null value */
      const hasExistingSubscription = existingSubscription !== null;

      if (hasExistingSubscription) return;

      const newSubscription =
        await serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: pushNotificationUtils.getPublicVapidKey(),
        });

      createWebPushDevice({
        variables: {
          input: pushNotificationUtils.prepareCreateWebPushDeviceInput({
            subscription: newSubscription,
            isMobileDevice,
          }),
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const getNotificationPermissions = async () => {
    try {
      const currentNotificationPermission = Notification.permission;

      switch (currentNotificationPermission) {
        case notificationPermissionEnum.granted: {
          return currentNotificationPermission;
        }

        case notificationPermissionEnum.default: {
          return await Notification.requestPermission();
        }

        default: {
          return null;
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const valueRefs = useValueRef({
    getNotificationPermissions,
    setupPushNotification,
  });

  /** Subscribe to push notification if permission granted */
  useEffect(() => {
    const isPushNotificationSupported =
      "serviceWorker" in navigator && "PushManager" in window;

    if (!isPushNotificationSupported) {
      console.error("Push notification isn't supported in this browser.");
      return;
    }

    const { getNotificationPermissions, setupPushNotification } =
      valueRefs.current;

    (async () => {
      try {
        const notificationPermission = await getNotificationPermissions();

        if (notificationPermission === notificationPermissionEnum.granted) {
          setupPushNotification();
          return;
        }
      } catch (error) {
        handleError(error);
      }
    })();
  }, [valueRefs]);

  return null;
};

export default PushNotificationManager;
