import { UAParser } from "ua-parser-js";

import { getServerSettings } from "@/utils/serverUtils";
import { webPushDeviceClientType } from "../enums";

const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  [...rawData].forEach(
    (data, index) => (outputArray[index] = rawData.charCodeAt(index)),
  );

  return outputArray;
};

const convertToBase64 = (key) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
};

const getWebPushDeviceClientType = (isMobileDevice) => {
  if (isMobileDevice) return webPushDeviceClientType.MOBILE;

  return webPushDeviceClientType.DESKTOP;
};

const getBrowserName = () => {
  /* Find out more user agents at https://www.whatismybrowser.com/guides/the-latest-user-agent/ */
  const userAgentParser = new UAParser(window.navigator.userAgent);
  const browserName = userAgentParser.getBrowser().name;

  return browserName.toUpperCase();
};

export const getPublicVapidKey = () => {
  const { vapidPublicApplicationServerKey } = getServerSettings();
  return urlBase64ToUint8Array(vapidPublicApplicationServerKey);
};

export const prepareCreateWebPushDeviceInput = ({
  subscription,
  isMobileDevice,
}) => {
  return {
    name: getBrowserName(),
    registrationId: subscription.endpoint,
    p256dh: convertToBase64(subscription.getKey("p256dh")),
    auth: convertToBase64(subscription.getKey("auth")),
    clientType: getWebPushDeviceClientType(isMobileDevice),
  };
};
