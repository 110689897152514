import { makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import DeclineCallIcon from "@/assets/images/icon-call-ended.svg";
import Button from "@/components/Button";

const useStyles = makeStyles(() => ({
  declineButton: {
    fontSize: "1.08rem",
    fontWeight: "500",
    "& .MuiButton-startIcon": {
      margin: "unset",
      "& path": {
        fill: "white",
      },
    },

    "&.isDisabled": {
      opacity: 0.5,
    },
  },

  buttonLabel: {
    flex: "1",
    justifyContent: "center",
  },
}));

const DeclineButton = ({ isDisabled, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      className={clsx(classes.declineButton, { isDisabled })}
      labelClassName={classes.buttonLabel}
      width="120px"
      height="36px"
      padding="0px 10px"
      disabled={isDisabled}
      startIcon={<DeclineCallIcon />}
      backgroundColor={{
        normal: theme.palette.alert[400],
        hover: theme.palette.alert.main,
        disabled: theme.palette.alert[400],
      }}
      onClick={onClick}
    >
      Decline
    </Button>
  );
};
export default DeclineButton;
