export const crmFilterLookupEnum = Object.freeze({
  EXACT: "EXACT",
  IEXACT: "IEXACT" /* Case-insensitive */,
  CONTAINS: "CONTAINS",
  ICONTAINS: "ICONTAINS" /* Case-insensitive */,
  IN: "IN",
  GT: "GT",
  GTE: "GTE",
  LT: "LT",
  LTE: "LTE",
  STARTSWITH: "STARTSWITH",
  ISTARTSWITH: "ISTARTSWITH" /* Case-insensitive */,
  ENDSWITH: "ENDSWITH",
  IENDSWITH: "IENDSWITH" /* Case-insensitive */,
  RANGE: "RANGE",
  ISNULL: "ISNULL",
  REGEX: "REGEX",
  IREGEX: "IREGEX" /* Case-insensitive */,
  DATE: "DATE",
  YEAR: "YEAR",
  ISO_YEAR: "ISO_YEAR",
  MONTH: "MONTH",
  DAY: "DAY",
  WEEK: "WEEK",
  WEEK_DAY: "WEEK_DAY",
  ISO_WEEK_DAY: "ISO_WEEK_DAY",
  QUARTER: "QUARTER",
  TIME: "TIME",
  HOUR: "HOUR",
  MINUTE: "MINUTE",
  SECOND: "SECOND",
  CONTAINED_BY: "CONTAINED_BY",
  HAS_KEY: "HAS_KEY",
  HAS_KEYS: "HAS_KEYS",
  HAS_ANY_KEYS: "HAS_ANY_KEYS",
  JSON_KEY: "JSON_KEY",
});

export const crmFilterOperatorEnum = Object.freeze({
  AND: "AND",
  OR: "OR",
});

export const instanceFormEnum = Object.freeze({
  export: "export",
  import: "import",
  create: "create",
});

export const crmSelectedInstancesOperationEnum = Object.freeze({
  add: "add",
  remove: "remove",
});

export const crmContactTagBulkOperationResponseEnum = Object.freeze({
  CRM_CONTACT_TAG_BULK_ADD_SUCCESS: "CRM_CONTACT_TAG_BULK_ADD_SUCCESS",
  CRM_CONTACT_TAG_BULK_ADD_FAILURE: "CRM_CONTACT_TAG_BULK_ADD_FAILURE",
  CRM_CONTACT_TAG_BULK_REMOVE_SUCCESS: "CRM_CONTACT_TAG_BULK_REMOVE_SUCCESS",
  CRM_CONTACT_TAG_BULK_REMOVE_FAILURE: "CRM_CONTACT_TAG_BULK_REMOVE_FAILURE",
});

export const crmInstanceListBulkOperationResponseEnum = Object.freeze({
  CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_SUCCESS:
    "CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_SUCCESS",
  CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_FAILURE:
    "CRM_BULK_ADD_INSTANCES_TO_INSTANCE_LISTS_FAILURE",
  CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_SUCCESS:
    "CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_SUCCESS",
  CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_FAILURE:
    "CRM_BULK_REMOVE_INSTANCES_FROM_INSTANCE_LISTS_FAILURE",
});
