import { gql } from "@apollo/client";

export const CREATE_WEB_PUSH_DEVICE = gql`
  mutation CreateWebPushDevice($input: CreateWebPushDeviceMutationInput!) {
    createWebPushDevice(input: $input) {
      instance {
        id
      }
    }
  }
`;
