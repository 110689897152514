import { useApolloClient, useSubscription } from "@apollo/client";
import { ErrorBoundary } from "@sentry/nextjs";
import { useRef } from "react";

import { AGENT_ONLINE_DATA_FRAGMENT } from "@/definitions/agentOnlineDataDefinition";
import { AGENT_INBOX_VIEW_PANE_FRAGMENT } from "@/definitions/allowedInboxView/inboxViewPaneDefinition";
import { dataObjectTypenameEnum } from "@/enums/typename";
import * as inboxPageQueries from "@/queries/inboxPageQueries";
import ConversationEventSubscription from "./ConversationEventSubscription";
import NotificationSubscription from "./NotificationSubscription";

const InboxPageSubscriptions = () => {
  const audioRef = useRef();
  const client = useApolloClient();

  useSubscription(
    inboxPageQueries.CONVERSATION_SPECIAL_ALLOWED_INBOX_VIEWS_COUNT_SUBSCRIPTION,
    {
      errorPolicy: "all",
      onData: ({ data }) => {
        const {
          data: {
            onUpdateAllowedInboxViewConversationCount: {
              allowedInboxViewSpecialsCount,
            } = {},
          } = {},
        } = data;

        client.cache.modify({
          id: "ROOT_QUERY",
          fields: {
            allowedInboxViews: (existing) => {
              return {
                ...existing,
                specialsCount: allowedInboxViewSpecialsCount,
              };
            },
          },
        });
      },
    },
  );

  /* Subscription for media transcription object when received incoming audio message */
  useSubscription(inboxPageQueries.MEDIA_TRANSCRIPTION_SUBSCRIPTION, {
    errorPolicy: "all",
  });

  /* Subscription for conversationMessages status list updates */
  useSubscription(
    inboxPageQueries.CONVERSATION_MESSAGE_STATUS_LIST_SUBSCRIPTION,
    { errorPolicy: "all" },
  );

  useSubscription(inboxPageQueries.AGENT_CURRENT_STATUS_SUBSCRIPTION, {
    errorPolicy: "all",
    onData: ({ data }) => {
      const { onAgentCurrentStatus } = data.data || {};
      if (onAgentCurrentStatus) return;

      const { agentId, agentCurrentStatus } = onAgentCurrentStatus;

      const cachedAgentData = client.readFragment({
        id: `${dataObjectTypenameEnum.agentObject}:${agentId}`,
        fragment: AGENT_INBOX_VIEW_PANE_FRAGMENT,
      });

      const newAgentData = {
        ...cachedAgentData,
        agentCurrentStatus,
      };

      client.writeFragment({
        id: `${dataObjectTypenameEnum.agentObject}:${agentId}`,
        fragment: AGENT_INBOX_VIEW_PANE_FRAGMENT,
        data: newAgentData,
      });
    },
  });

  useSubscription(inboxPageQueries.AGENT_ONLINE_DATA_SUBSCRIPTION, {
    errorPolicy: "all",
    onData: ({ data }) => {
      const { onAgentOnlineData } = data.data || {};
      if (onAgentOnlineData) return;

      const { agentOnlineData } = onAgentOnlineData;

      const id = client.cache.identify(agentOnlineData);

      client.writeFragment({
        id,
        fragment: AGENT_ONLINE_DATA_FRAGMENT,
        data: agentOnlineData,
      });
    },
  });

  const playNotificationSound = async () => {
    try {
      if (audioRef.current) await audioRef.current.play();
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <ErrorBoundary fallback={null}>
        <NotificationSubscription
          onPlayNotificationSound={playNotificationSound}
        />
      </ErrorBoundary>

      <ErrorBoundary fallback={null}>
        <ConversationEventSubscription
          onPlayNotificationSound={playNotificationSound}
        />
      </ErrorBoundary>

      <audio ref={audioRef}>
        <source src="/sounds/chat_alert.wav" type="audio/wav" />
        <source src="/sounds/chat_alert.mp3" type="audio/mpeg" />
      </audio>
    </>
  );
};

export default InboxPageSubscriptions;
